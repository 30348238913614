<template>
    <div class="page-container">
      <div class="content-wrap">
        <div class="content">
          <PrivacySection />
        </div>
      </div>
      <FooterSection />
    </div>
  </template>
  
  <script>
  import PrivacySection from '../components/PrivacySection.vue';
  import FooterSection from '@/components/FooterSection.vue';
  
  export default {
    name: 'PrivacyPage',
    components: {
      PrivacySection,
      FooterSection,
    },
  };
  </script>
  
  <style scoped>
  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .privacy {
    padding: 100px 20px;
    background: #f9f9f9;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .privacy-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .privacy-item {
    flex: 1 1 calc(33.333% - 20px);
    background: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
  }
  
  .privacy-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  </style>