<template>
    <div class="page-container">
      <div class="content-wrap">
        <div class="content">
          <TermsSection />
        </div>
      </div>
      <FooterSection />
    </div>
  </template>
  
  <script>
  import TermsSection from '../components/TermsSection.vue';
  import FooterSection from '@/components/FooterSection.vue';
  
  export default {
    name: 'TermsPage',
    components: {
      TermsSection,
      FooterSection,
    },
  };
  </script>
  
  <style scoped>
  .features {
    padding: 100px 20px;
    background: #f9f9f9;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  </style>