
<template>
  <div class="terms-section">
    <div class="terms-content">
      <p>Last updated July 9, 2024</p>
      <h2>Introduction</h2>
      <p>Thanks for choosing SideAssure Inc DBA TOZNY Inc (“Tozny”) products and services (“Services”). By using our Services you (“Customer” or “you”) automatically agree to the terms of this Terms of Service (“Agreement”) so please read them carefully. If you are entering into this Agreement on behalf of a company or other legal entity you represent that you have the authority to bind such entity and its Affiliates to this Agreement. If you do not agree with these terms or if you do not have the authority described above do not accept this Agreement and you may not use the Services.</p>
      <p>Since Tozny provides a variety of Services additional terms and requirements may apply. Those additional terms will be available with the applicable Services and those additional terms are part of your agreement with Tozny if you choose to use those Services.</p>
      <h2>1. Definitions</h2>
      <p>“Customer Data” means all data or information submitted by Customer Users or Tozny on the Customer’s behalf for the purpose of using the Services or facilitating the Customer’s use of the Services.</p>
      <p>“End User” means any individual or entity that has access or a right to use the Services through a sublicense from Customer which sublicense contains terms no less restrictive than those set forth in this Agreement.</p>
      <p>“Application Data” means data which Customer may elect to use the Services to encrypt and store within the Services. Application Data may include personal Customer Data End User data or other data transmitted to the Services by Customer and which Customer has ownership and responsibility for per the terms of this Agreement.</p>
      <p>“Service” or “Services” means Tozny’s technology products and services which may include identity management end-to-end encryption and storage multi factor authentication related software development kits and other products and services provided by Tozny across all available platforms developed and provided by Tozny.</p>
      <p>“Users” means those employees agents and independent contractors of the Customer who are authorized by the Customer to use the Services and that have registered for Tozny user identifications and passwords.</p>
      <p>“User Data” means the information that Users provide in registration for the Services and any and all information subsequently uploaded to the Services.</p>
      <h2>2. Using the Services</h2>
      <h3>2.1 Customer Responsibilities</h3>
      <p>Customer shall: (i) have sole responsibility for the accuracy quality integrity legality reliability and appropriateness of all Customer Data and Application Data; (ii) obtain and maintain any equipment and ancillary services needed to connect to access or otherwise use the Services; and (iii) comply with all applicable local state federal and foreign laws in its use of the Services (including applicable export control laws and regulations).</p>
      <h3>2.2 Use Guidelines</h3>
      <p>Customer shall not knowingly or intentionally: (i) license sublicense sell resell rent lease transfer assign distribute infringe on anyone’s rights time share or otherwise commercially exploit or make the Services available to any third party other than as contemplated by this Agreement; (ii) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; (iii) permit Users to send or store infringing obscene threatening libelous or otherwise unlawful or tortious material including material harmful to children or which may be in violation of any third party privacy rights; (iv) send or store material containing software viruses worms Trojan horses or other harmful computer code files scripts agents or programs; (v) interfere with or disrupt the integrity or performance of the Service or the data contained therein; or (vi) attempt to gain unauthorized access to the Services or its related systems or networks.</p>
      <p>If we determine in our sole discretion that you have not complied with our guidelines terms or policies we may terminate your use of the Services and take appropriate legal actions.</p>
      <h2>3. Your Accounts</h2>
      <p>You may need to create an account in order to use some of the Services. When creating your account you must provide truthful and accurate information about yourself. Do not try to impersonate anyone else when you create your account. If your information changes at any time you are responsible for updating your account to reflect those changes.</p>
      <p>In some cases an account may be assigned to you by an administrator such as your employer or education institution. If you are logging into an account assigned to you by an administrator your administrator may be able to access or disable your account without our involvement.</p>
      <p>To protect your Tozny account keep your credential(s) confidential. You are responsible for the activity that happens on or through your account. If you learn of any unauthorized use of your credential or account please notify Tozny immediately at privacy@tozny.com.</p>
      <h2>4. Your Content in the Services</h2>
      <p>Some of the Services allow you to upload submit store send or receive content. You retain ownership of any intellectual property rights that you hold in that content.</p>
      <p>So Tozny can deliver the Services effectively to you and your users when you upload submit store send or receive content to or through the Services you grant Tozny (and Tozny’s affiliates) a perpetual worldwide license to use host store reproduce or modify such content. This license is for the limited purpose of operating promoting and improving the Services and to develop new ones. You are responsible for making sure that you have the necessary rights to grant us this license for any content that you submit to the Services. Please refer to Tozny’s Privacy Policy for additional information.</p>
      <p>You retain the credentials and encryption keys required to encrypt and access any Application Data you encrypt and store in the Services. You acknowledge that Tozny does not have access to any Application Data and does not have the ability to recover encrypted Application Data in the event you lose your keys. You are solely responsible for the Customer Data and Application Data as stated in Section 2 of this Agreement and for compliance of the data with regard to any specific regulations applicable to its content.</p>
      <h2>5. Tozny Software in the Services</h2>
      <p>When use of the Services requires or includes downloadable software Tozny grants you a personal worldwide royalty-free non-assignable and non-exclusive license to use the software provided to you by Tozny as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as permitted by these terms. You may not copy modify distribute sell or lease any part of the Services or included software nor may you reverse engineer or attempt to extract the source code of that software unless laws prohibit these restrictions or you have our written permission.</p>
      <p>Tozny is committed to using open-source software when possible. Some software used in the Services may be offered under an open-source license that will be made available to you. There may be provisions in these open-source licenses that expressly override some of these terms.</p>
      <h2>6. Modifying and Terminating the Services</h2>
      <p>Tozny is committed to continuously providing you access to the Service but unforeseen circumstances may not allow it to be always available. Tozny is constantly updating the Services to improve performance. We may add functionality or features and we may suspend or stop functionality or features of the Services altogether. Tozny may stop providing Services to you or add or create new limits on the Services at any time. If we discontinue a Service where possible we will give you reasonable advance notice and an opportunity to retrieve information out of that Service. You may stop utilizing the Service and remove your data from the Service at your discretion. This does not change any agreed terms regarding compensation for services or other contracted agreements.</p>
      <h2>7. Support</h2>
      <p>Tozny shall use good industry standard practice methods and technology to maintain the security and integrity of the Services. We will perform the Services with the best care and skill and in accordance with generally recognized commercial practices and standards in the industry for similar services and will use commercially reasonable efforts to make the Services generally available at all times except for downtime caused by circumstances beyond Tozny’s reasonable control which may include but is not limited to computer Internet or telecommunications failures or delays involving hardware or software not within Tozny’s possession or reasonable control.</p>
      <p>Tozny provides a basic level of support to paid Customers and Users. Contact us at info@tozny.com for details on obtaining priority support. Users may submit requests to Tozny for support concerns at support@tozny.com or at our contact page.</p>
      <h2>8. Fees Taxes and Cancellations</h2>
      <h3>8.1 Fees and Payments</h3>
      <p>In consideration for access and use of the Services and except for optionally provided introductory pricing or free trial access to the Services provided at Tozny’s sole discretion you shall pay Tozny the applicable fees per the subscription level of your Services account.</p>
      <p>Tozny reserves the right to change the pricing of the Services or our billing practices at any time. Changes will be reflected in updates to the pricing on our website or by notifying you by email. Subscription fees are stated in US dollars.</p>
          <p>You must provide us with a valid credit card via your Services account in order to receive paid Services. By providing your payment information you authorize Tozny to bill and collect fees from you when they become due without requiring additional notice or consent.</p>
    <p>Fees and other payments related to your use of the Services shall be made in accordance with the subscription details of your account and per the terms in effect at the time your payment is due. Payments made are non-refundable.</p>
    <h3>8.2 Taxes</h3>
    <p>Fees stated and billed are exclusive of any taxes levies duties or similar governmental assessments of any nature including for example value-added sales use or withholding taxes assessable by any jurisdiction (collectively “Taxes”). You will be responsible for paying all Taxes associated with fees paid for the Services except for those taxes based on our net income.</p>
    <h3>8.3 Changes to Your Information</h3>
    <p>You are responsible for updating any changes to your payment information in your Services account. If you fail to pay or fail to update your payment information such that your payment is not processed we may in addition to any of our other rights or remedies suspend your access to the Services without liability until such amounts are paid in full.</p>
    <h3>8.4 Automatic Renewal and Cancellation</h3>
    <p>Your subscription to the Services will automatically renew each anniversary (annually or monthly) unless you cancel your subscription. You may update or cancel your subscription to the Services at any time by logging into your account and navigating to Settings > Subscription view of your account dashboard.</p>
    <h2>9. Warranties and Disclaimers</h2>
    <h3>9.1 Warranties</h3>
    <p>(a) Each party represents and warrants to the other that it has the legal power to enter into deliver and perform this Agreement.</p>
    <p>(b) Customer represents and warrants that (i) it has the legal right and authority and will continue to own or maintain the legal right and authority during the term of the Agreement to provide and use (itself and through the Services) the Customer Data or other content provided by Customer in conjunction with the Services; and (ii) the performance of its obligations and use of the Services by Customer and Users will not violate any applicable laws regulations or this Agreement.</p>
    <h3>9.2 Disclaimer</h3>
    <p>OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OF SERVICE OR ADDITIONAL TERMS SPECIFIC TO A PARTICULAR SERVICE NEITHER TOZNY OR ITS AFFILIATES MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE WE DO NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES THE SPECIFIC FUNCTIONS OF THE SERVICES OR THEIR RELIABILITY AVAILABILITY OR ABILITY TO MEET YOUR NEEDS. ALL SERVICES ARE PROVIDED “AS IS.”</p>
    <p>TO THE EXTENT PERMITTED BY LAW TOZNY EXCLUDES ALL WARRANTIES INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILTIY FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
    <h2>10. Limitation of Liability</h2>
    <p>WHEN PERMITTED BY LAW TOZNY AND TOZNY’S AFFILIATES WILL NOT BE RESPONSIBLE FOR LOST PROFITS REVENUES OR DATA FINANCIAL LOSSES OR INDIRECT SPECIAL CONSEQUENTIAL EXEMPLARY OR PUNITIVE DAMAGES.</p>
    <p>TO THE EXTENT PERMITTED BY LAW THE TOTAL LIABILITY OF TOZNY AND ITS AFFILIATES FOR ANY CLAIMS UNDER THESE TERMS OF SERVICE INCLUDING FOR ANY IMPLIED WARRANTIES IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR IF TOZNY CHOOSES TO SUPPLYING YOU THE SERVICES AGAIN).</p>
    <p>IN NO CASE WILL TOZNY OR ITS AFFILIATES BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>
    <h2>11. Proprietary Rights</h2>
    <h3>11.1 Reservation of Rights by Tozny</h3>
    <p>Other than as expressly set forth herein nothing in this Agreement shall grant to Customer any (i) license or other rights in or to the Tozny name any Tozny logo the Tozny domain name or any other Tozny trademark or service mark; or (ii) ownership rights in any Tozny technology software hardware products processes algorithms user interfaces know-how trade secrets techniques designs inventions or other tangible or intangible technical material or information (collectively “Tozny Technology”) any and all of which are hereby expressly reserved to Tozny.</p>
    <p>All other names logos product and service names designs and slogans on or in the Services are the trademarks of their respective owners.</p>
    <h3>11.2 Restrictions</h3>
    <p>Customer shall not (i) create any Internet “links” to or from the Services or “frame” or “mirror” any content forming part of the Services other than on Tozny’s own intranet or otherwise for its own internal business purposes; or (ii) disassemble reverse engineer or decompile the Services in any way.</p>
    <h3>11.3 Customer Data and Application Data</h3>
    <p>As between Tozny and Customer all Customer Data and Application Data is owned exclusively by Customer. Customer Data and Application Data shall be considered Confidential Information. Except for those express licenses granted hereunder Tozny shall not gain by virtue of this Agreement any rights of any other intellectual property or proprietary rights in the Customer Data and Application Data.</p>
    <h2>12. Confidentiality</h2>
    <h3>12.1 Definition of Confidential Information</h3>
    <p>As used herein “Confidential Information” means all confidential and proprietary information of a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”) whether orally or in writing that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. Confidential Information shall not include any information that: (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party; (ii) is aggregate data at the industry level that does not uniquely identify customers or individuals; (iii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party; (iv) was independently developed by the Receiving Party without breach of any obligation owed to the Disclosing Party; or (v) is received from a third party without breach of any obligation owed to the Disclosing Party.</p>
    <h3>12.2 Confidentiality</h3>
    <p>The Receiving Party shall not disclose or use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement except with the Disclosing Party’s prior written permission.</p>
    <h3>12.3 Protection</h3>
    <p>Each party agrees to protect the confidentiality of the Confidential Information of the other party in the same manner that it protects the confidentiality of its own proprietary and confidential information of like kind but in no event shall either party exercise less than reasonable care in protecting such Confidential Information.</p>
    <h3>12.4 Compelled Disclosure</h3>
    <p>If the Receiving Party is compelled by law to disclose Confidential Information of the Disclosing Party it shall provide the Disclosing Party with prior notice of such compelled disclosure (to the extent legally permitted) and reasonable assistance at Disclosing Party’s cost if the Disclosing Party wishes to contest the disclosure.</p>
    <h3>12.5 Remedies</h3>
    <p>If the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential Information of the Disclosing Party in breach of this Section 12 the Disclosing Party shall have the right in addition to any other remedies available to it to seek injunctive relief to enjoin such acts it being specifically acknowledged by the parties that any other available remedies are inadequate.</p>
    <h2>13. Term and Termination</h2>
    <p>This Agreement and the Services may be terminated by Tozny at any time with or without notice to you. Tozny reserves the right to disable any User’s account use of or access to the Services for any reason and without notice. When this Agreement expires or terminates Tozny shall cease providing the Services to you. You shall immediately cease using the Services. Sections 2 4 7 9 10 11 12 13 14 and 15 shall survive the termination or expiration of this Agreement.</p>
    <h2>14. Business Use of the Services</h2>
    <p>If you are using the Services on behalf of a business that business accepts these terms. The business will hold harmless Tozny and its affiliates from any claim suit or action arising from or related to the use of the Services or violation of these terms including any liability or expense arising from claims losses damages suits judgments litigation costs and attorneys’ fees.</p>
    <h2>15. About These Terms</h2>
    <p>You must provide us with a valid credit card via your Services account in order to receive paid Services. By providing your payment information you authorize Tozny to bill and collect fees from you when they become due without requiring additional notice or consent.</p>
    <p>Fees and other payments related to your use of the Services shall be made in accordance with the subscription details of your account and per the terms in effect at the time your payment is due. Payments made are non-refundable.</p>
    <h3>8.2 Taxes</h3>
    <p>Fees stated and billed are exclusive of any taxes levies duties or similar governmental assessments of any nature including for example value-added sales use or withholding taxes assessable by any jurisdiction (collectively “Taxes”). You will be responsible for paying all Taxes associated with fees paid for the Services except for those taxes based on our net income.</p>
    <h3>8.3 Changes to Your Information</h3>
    <p>You are responsible for updating any changes to your payment information in your Services account. If you fail to pay or fail to update your payment information such that your payment is not processed we may in addition to any of our other rights or remedies suspend your access to the Services without liability until such amounts are paid in full.</p>
    <h3>8.4 Automatic Renewal and Cancellation</h3>
    <p>Your subscription to the Services will automatically renew each anniversary (annually or monthly) unless you cancel your subscription. You may update or cancel your subscription to the Services at any time by logging into your account and navigating to Settings > Subscription view of your account dashboard.</p>
    <h2>9. Warranties and Disclaimers</h2>
    <h3>9.1 Warranties</h3>
    <p>(a) Each party represents and warrants to the other that it has the legal power to enter into deliver and perform this Agreement.</p>
    <p>(b) Customer represents and warrants that (i) it has the legal right and authority and will continue to own or maintain the legal right and authority during the term of the Agreement to provide and use (itself and through the Services) the Customer Data or other content provided by Customer in conjunction with the Services; and (ii) the performance of its obligations and use of the Services by Customer and Users will not violate any applicable laws regulations or this Agreement.</p>
    <h3>9.2 Disclaimer</h3>
    <p>OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OF SERVICE OR ADDITIONAL TERMS SPECIFIC TO A PARTICULAR SERVICE NEITHER TOZNY OR ITS AFFILIATES MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE WE DO NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES THE SPECIFIC FUNCTIONS OF THE SERVICES OR THEIR RELIABILITY AVAILABILITY OR ABILITY TO MEET YOUR NEEDS. ALL SERVICES ARE PROVIDED “AS IS.”</p>
    <p>TO THE EXTENT PERMITTED BY LAW TOZNY EXCLUDES ALL WARRANTIES INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILTIY FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
    <h2>10. Limitation of Liability</h2>
    <p>WHEN PERMITTED BY LAW TOZNY AND TOZNY’S AFFILIATES WILL NOT BE RESPONSIBLE FOR LOST PROFITS REVENUES OR DATA FINANCIAL LOSSES OR INDIRECT SPECIAL CONSEQUENTIAL EXEMPLARY OR PUNITIVE DAMAGES.</p>
    <p>TO THE EXTENT PERMITTED BY LAW THE TOTAL LIABILITY OF TOZNY AND ITS AFFILIATES FOR ANY CLAIMS UNDER THESE TERMS OF SERVICE INCLUDING FOR ANY IMPLIED WARRANTIES IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR IF TOZNY CHOOSES TO SUPPLYING YOU THE SERVICES AGAIN).</p>
    <p>IN NO CASE WILL TOZNY OR ITS AFFILIATES BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>
    <h2>11. Proprietary Rights</h2>
    <h3>11.1 Reservation of Rights by Tozny</h3>
    <p>Other than as expressly set forth herein nothing in this Agreement shall grant to Customer any (i) license or other rights in or to the Tozny name any Tozny logo the Tozny domain name or any other Tozny trademark or service mark; or (ii) ownership rights in any Tozny technology software hardware products processes algorithms user interfaces know-how trade secrets techniques designs inventions or other tangible or intangible technical material or information (collectively “Tozny Technology”) any and all of which are hereby expressly reserved to Tozny.</p>
    <p>All other names logos product and service names designs and slogans on or in the Services are the trademarks of their respective owners.</p>
    <h3>11.2 Restrictions</h3>
    <p>Customer shall not (i) create any Internet “links” to or from the Services or “frame” or “mirror” any content forming part of the Services other than on Tozny’s own intranet or otherwise for its own internal business purposes; or (ii) disassemble reverse engineer or decompile the Services in any way.</p>
    <h3>11.3 Customer Data and Application Data</h3>
    <p>As between Tozny and Customer all Customer Data and Application Data is owned exclusively by Customer. Customer Data and Application Data shall be considered Confidential Information. Except for those express licenses granted hereunder Tozny shall not gain by virtue of this Agreement any rights of any other intellectual property or proprietary rights in the Customer Data and Application Data.</p>
    <h2>12. Confidentiality</h2>
    <h3>12.1 Definition of Confidential Information</h3>
    <p>As used herein “Confidential Information” means all confidential and proprietary information of a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”) whether orally or in writing that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. Confidential Information shall not include any information that: (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party; (ii) is aggregate data at the industry level that does not uniquely identify customers or individuals; (iii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party; (iv) was independently developed by the Receiving Party without breach of any obligation owed to the Disclosing Party; or (v) is received from a third party without breach of any obligation owed to the Disclosing Party.</p>
    <h3>12.2 Confidentiality</h3>
    <p>The Receiving Party shall not disclose or use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement except with the Disclosing Party’s prior written permission.</p>
    <h3>12.3 Protection</h3>
    <p>Each party agrees to protect the confidentiality of the Confidential Information of the other party in the same manner that it protects the confidentiality of its own proprietary and confidential information of like kind but in no event shall either party exercise less than reasonable care in protecting such Confidential Information.</p>
    <h3>12.4 Compelled Disclosure</h3>
    <p>If the Receiving Party is compelled by law to disclose Confidential Information of the Disclosing Party it shall provide the Disclosing Party with prior notice of such compelled disclosure (to the extent legally permitted) and reasonable assistance at Disclosing Party’s cost if the Disclosing Party wishes to contest the disclosure.</p>
    <h3>12.5 Remedies</h3>
    <p>If the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential Information of the Disclosing Party in breach of this Section 12 the Disclosing Party shall have the right in addition to any other remedies available to it to seek injunctive relief to enjoin such acts it being specifically acknowledged by the parties that any other available remedies are inadequate.</p>
    <h2>13. Term and Termination</h2>
    <p>This Agreement and the Services may be terminated by Tozny at any time with or without notice to you. Tozny reserves the right to disable any User’s account use of or access to the Services for any reason and without notice. When this Agreement expires or terminates Tozny shall cease providing the Services to you. You shall immediately cease using the Services. Sections 2 4 7 9 10 11 12 13 14 and 15 shall survive the termination or expiration of this Agreement.</p>
    <h2>14. Business Use of the Services</h2>
    <p>If you are using the Services on behalf of a business that business accepts these terms. The business will hold harmless Tozny and its affiliates from any claim suit or action arising from or related to the use of the Services or violation of these terms including any liability or expense arising from claims losses damages suits judgments litigation costs and attorneys’ fees.</p>
    <h2>15. About These Terms</h2>
    <p>Tozny may modify these Terms of Service or any additional terms that apply to a particular Service at any time. You should look at these terms regularly. Notice of modification to these terms will be posted on this page. Notice of modification to additional terms will be posted on the applicable pages. Changes will not apply retroactively and will become effective on the date on which they are posted. If you do not agree to the modified terms please discontinue your use of the Services immediately.</p>
    <p>If there is a conflict between these terms and the additional terms, the additional terms will control.</p>
    <p>If you do not comply with these terms, and Tozny does not immediately take action, this does not mean that we are giving up any rights that we may have including taking future action.</p>
    <p>If any term is not enforceable, it will not affect any other term.</p>
    <p>The laws of Washington, U.S.A., excluding any conflict of law rules, will apply to any disputes arising out of or relating to these terms or the Services. All claims arising out of these terms or the Services will be litigated exclusively in the federal or state courts of Clark County, Washington, U.S.A. You and Tozny consent to personal jurisdiction in those courts.</p>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'TermsSection',
  };
  </script>
  
  <style scoped>
  .terms-section {
    padding: 2rem;
    background: linear-gradient(0deg, #e9f5ff 0%, #ffffff 100%);
    display: flex;
    justify-content: center;
  }
  
  .terms-content {
    max-width: 1024px;
    width: 100%;
  }
  
  .terms-section h2 {
    font-size: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .terms-section p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .terms-section ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 1rem;
    padding-left: 20px;
  }
  
  .terms-section ul li {
    margin-bottom: 0.5rem;
    padding-left: 20px;
  }
  </style>