<template>
  <div class="privacy-section">
    <div class="privacy-content">
      <p><span>Last modified: July 9, 2024</span></p>
    <h2><span>Introduction</span></h2>
    <p><span>SideAssure Inc, DBA Tozny, Inc. (“Company” or “We”) respect your privacy and are committed to protecting it.</span></p>
    <p><span>This Privacy Policy (“Policy”) describes the types of information we may collect from you or that you may provide when you visit the website <a href="https://tozny.com">Tozny.com</a> or Company content accessible through our website or your use of Tozny products and services (collectively the “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that information.</span></p>
    <p><span>This policy applies to information we collect:</span></p>
    <ul>
      <li><span>On the <a href="https://tozny.com">Tozny.com</a> website.</span></li>
      <li><span>In email, text, and other electronic messages between you and the Services.</span></li>
      <li><span>Through your use of the Services.</span></li>
    </ul>
    <p><span>Note that Tozny does not store or maintain the cryptographic keys necessary to decrypt any data you elect to store in the Services. Access to that data is solely your responsibility and subject to your privacy policy.</span></p>
    <p><span>Please read this Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, you must not use the Services. By accessing or using the Services, you agree to this Policy. This Policy may change from time to time (see “Changes to Our Privacy Policy”). Your continued use of the Services after we make changes is deemed to be acceptance of those changes, so please check the Policy periodically for updates.</span></p>
    <h2><span>Children Under the Age of 13</span></h2>
    <p><span>The Services are not intended for children under 13 years of age. No one under age 13 may provide any information to the Services. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on or through the Services. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at <a href="mailto:privacy@tozny.com">privacy@tozny.com</a>.</span></p>
    <h2><span>Information We Collect About You and How We Collect It</span></h2>
    <p><span>We collect certain types of information from and about users of the Services, including information by which you may be personally identified, such as name, postal address, e-mail address, and telephone number (“Personal Information”), information that is about you but individually does not identify you, and information about your internet connection, the equipment you use to access the Services, and usage details.</span></p>
    <p><span><i>Information You Provide to Us Through the Use of the Services.</i> The information we collect on or through the Services may include:</span></p>
    <ul>
      <li><span>Information that you provide by filling in forms on the Services. This includes information provided at the time of registering for use of the Services, or requesting further information or services. This information may include, but is not limited to name, email, and account login credentials.</span></li>
      <li><span>Records and copies of your correspondence (including name, email addresses, and telephone number), if you contact us.</span></li>
    </ul>
    <p><span>Note that access to our website is available without providing this information, however you may not be able to access and use some of the Services without the requested information.</span></p>
    <p><span><i>Information We Collect Through Automatic Data Collection Technologies.</i> As you navigate through and interact with the Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</span></p>
    <ul>
      <li><span>Details of your visits to the Services, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Services.</span></li>
      <li><span>Information about your computer and internet connection, including your IP address, operating system, browser type, broad geographic location, and other technical information.</span></li>
    </ul>
    <p><span>More information about Cookies is available below (“Use of Cookies”).</span></p>
    <p><span>The information we collect automatically is statistical data and does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. This information is used for internal analysis and helps us to improve the Services and to deliver a better and more personalized service, including by enabling us to:</span></p>
    <ul>
      <li><span>Estimate our audience size and usage patterns.</span></li>
      <li><span>Store information about your preferences, allowing us to customize the Services according to your individual interests.</span></li>
      <li><span>Recognize you when you return to the Services.</span></li>
    </ul>
    <p><span>We do not collect personal information automatically, but we may associate this information to personal information about you that we collect from other sources or you provide to us. Note that for some jurisdictions, for example countries in the European Economic Area (“EEA”) and the state of California, the automatically collected information may be considered Personal Information under applicable data protection laws for those contexts.</span></p>
    <h2><span>How We Use Your Information</span></h2>
    <p><span>We use information that we collect about you or that you provide to us, including any personal information:</span></p>
    <ul>
      <li><span>To present the Services and its contents to you.</span></li>
      <li><span>To provide you with information, products, or services that you request from us.</span></li>
      <li><span>To fulfill any other purpose for which you provide it.</span></li>
      <li><span>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</span></li>
      <li><span>To notify you about changes to our policies, Services, or any other products or services we offer or provide.</span></li>
      <li><span>In any other way we may describe when you provide the information.</span></li>
      <li><span>For any other purpose with your consent.</span></li>
    </ul>
    <h2><span>Disclosure of Your Information</span></h2>
    <p><span>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</span></p>
    <p><span>We may disclose personal information that we collect or you provide as described in this privacy policy:</span></p>
    <ul>
      <li><span>To our subsidiaries and affiliates.</span></li>
      <li><span>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</span></li>
      <li><span>To fulfill the purpose for which you provide it.</span></li>
      <li><span>For any other purpose disclosed by us when you provide the information.</span></li>
      <li><span>With your consent.</span></li>
    </ul>
    <p><span>We may also disclose your personal information:</span></p>
    <ul>
      <li><span>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</span></li>
      <li><span>To enforce or apply our <a href="https://tozny.com/terms-of-use/">Terms of Use</a>, Terms of Service, and other agreements, including for billing and collection purposes.</span></li>
    </ul>
    <h2><span>Use of Cookies</span></h2>
    <p><span>Cookies are small data files that are placed on your computer, mobile, or other device you use to visit a website or use a service. Cookies are widely used in order to make websites and services function properly or more efficiently, and are also used to provide information as described above about you and your visit to, or use of, the Services.</span></p>
    <p><span>We may elect to use both session (or transient) cookies which expire when you close your web browser or mobile application, and persistent cookies which stay on your computer until you delete them (or when they expire based on the duration set by the website or application). Cookies are used to assist us in providing you with a better experience when visiting or using the Services.</span></p>
    <p><span>You may manage how your browser handles cookies by adjusting its privacy and security settings at the individual browser level. Please see your specific browser’s settings help menu for additional information as privacy and security settings may vary by browser.</span></p>
    <h2><span>Accessing, Correcting, and Retention of Your Information</span></h2>
    <p><span>To the extent you provide us with the necessary information, we keep your information accurate and up to date. If your personal information changes (for example, you have a new email address or updates to your billing information), then you are responsible for notifying us of any updates and/or making changes to your information in your account within the Services. You can review and change your personal information by logging into your Services’ account at the <a href="https://dashboard.tozny.com/login">Tozny Dashboard</a>.</span></p>
    <p><span>You may also send us an email at <a href="mailto:privacy@tozny.com">privacy@tozny.com</a> to request access to, correct or delete any personal information that you have provided to us. Note that we cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</span></p>
    <p><span>We will retain your information for as long as your account is active or as long as needed to provide the Services to you. As mentioned above, we may also retain and use your information in order to comply with any court order, law, or legal process, including to respond to any government or regulatory request.</span></p>
    <h2><span>Geography of Operations</span></h2>
    <p><span>As the Services, the systems and servers on which they operate, and our offices are located in the United States, your information will be stored and processed in the United States. We care about and take many steps to protect your privacy. This includes offering Data Processing Agreement addendum to our Terms of Service (available upon request). By using the Services, you understand and consent to the collection, storage, processing, and transfer of your information to the Services in the United States.</span></p>
    <h2><span>California Privacy Rights</span></h2>
    <p><span>The following provides additional details about the Personal Information we collect about California consumers and their rights under the California Consumer Privacy Act (“CCPA”).</span></p>
    <p><span>Personal Information we collect, and the purposes for which we collect it, is described above. We do not sell the Personal Information we collect and will not sell it without providing the right to opt out. Additionally, we do not use third-party cookies for the purpose of advertising.</span></p>
    <p><span>Subject to certain limitations, the CCPA provides California consumers the right to request to know more details about the categories or specific pieces of Personal Information we collect (including how we use and disclose this information), to delete their personal information, to opt out of any “sales,” and to not be discriminated against for exercising these rights.</span></p>
    <p><span>California consumers may make requests related to their rights under the CCPA by contacting us at <a href="mailto:privacy@tozny.com">privacy@tozny.com</a>. We will verify your request using the information you have provided that is associated with your account, however additional identification may be required to validate the request.</span></p>
    <h2><span>Changes to Our Privacy Policy</span></h2>
    <p><span>We may make changes to this Policy at any time. It is our policy to post any changes we make to our privacy policy on this page and as referenced elsewhere in the Services at our discretion. If you have an account for the Services and we make material changes to how we treat our users’ personal information, we will notify you by email to the email address specified in your account and/or through a notice on the website home page as required by applicable law.</span></p>
    <p><span>The date the Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting the Services and this privacy policy to check for any changes.</span></p>
    <h2><span>Contact Information</span></h2>
    <p><span>Thank you for taking the time to read this Privacy Policy. To ask questions or comment about this Privacy Policy and our privacy practices, contact us at: <a href="mailto:privacy@tozny.com">privacy@tozny.com</a>.</span></p>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'PrivacySection',
  };
</script>
  
<style scoped>
.privacy-section {
  padding: 2rem;
  background: linear-gradient(0deg, #e9f5ff 0%, #ffffff 100%);
  display: flex;
  justify-content: center;
}

.privacy-section h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.privacy-section p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.privacy-section ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 1rem;
  padding-left: 20px;
}

.privacy-section ul li {
  margin-bottom: 0.5rem;
  padding-left: 20px;
}

.privacy-content {
  max-width: 1024px;
  width: 100%;
}
</style>