<template>
  <section id="key-features-section" class="key-features bg-gradient-to-b from-[#e9f5ff] to-white py-16 px-4">
    <div class="container mx-auto">
      <h1 class="text-5xl font-bold text-center text-dark-text mb-12">Leave your (cyber) insecurities behind</h1>

       <!-- Dashboard Row -->
       <div class="dashboard-row flex flex-col items-center mb-10 py-10">
        <div class="dashboard-image max-w-full rounded-lg mb-10">
          <img class="scalable dashboard-img" src="@/assets/dashboard_flow.png" alt="Flow Dashboard">
        </div>
        <div class="dashboard-content text-center max-w-3xl">
          <div class="flex flex-col items-center">
            <h3 class="text-3xl font-bold mb-4">Everything you need in one place</h3>
            <p class="text-body-text">TOZ Flow brings together all of the features your team needs to safely collaborate with sensitive data. They can organize their own vault by creating or uploading files, access secure groups to work with teams they belong to, and launch apps they have been given access to.</p>
          </div>
        </div>
      </div>

      <!-- Row 1 -->
      <div class="feature-row flex flex-col md:flex-row items-center mb-10 py-10">
        <div class="feature-image flex-1 px-4 mb-10 md:mb-0">
          <img class="scalable w-full max-w-xl mx-auto rounded-lg transition-transform transform hover:scale-105" src="@/assets/collaborate.png" alt="Share and Collaborate">
        </div>
        <div class="feature-content flex-1 px-4 text-left">
          <h3 class="text-2xl font-semibold mb-2">Create safe spaces to work</h3>
          <p class="text-body-text">Now your team can work together with guests in privileged access protected groups, to ensure only the right people access resources available, for only as long as is necessary, with the rights they need.</p>
        </div>
      </div>

      <!-- Row 2 -->
      <div class="feature-row flex flex-col md:flex-row items-center mb-10 py-10">
        <div class="feature-image flex-1 px-4 mb-10 md:mb-0">
          <img class="scalable w-full max-w-xl mx-auto rounded-lg transition-transform transform hover:scale-105" src="@/assets/docx.png" alt="Protect Data and Applications">
        </div>
        <div class="feature-content flex-1 px-4 text-left">
          <h3 class="text-2xl font-semibold mb-2">Protect data and applications</h3>
          <p class="text-body-text">Notes, password credentials, and files are stored in vaults, encrypted from end-to-end with keys derived by user identity. Teams can work on DOCX native documents together without ever leaving Flow. Apps can be assigned to users or groups, and safely launched using the Launchpad.</p>
        </div>
      </div>

      <!-- Row 3 -->
      <div class="feature-row flex flex-col md:flex-row items-center mb-10 py-10">
        <div class="feature-image flex-1 px-4 mb-10 md:mb-0">
          <img class="scalable w-full max-w-xl mx-auto rounded-lg transition-transform transform hover:scale-105" src="@/assets/identify.png" alt="Verify Trusted Identities">
        </div>
        <div class="feature-content flex-1 px-4 text-left">
          <h3 class="text-2xl font-semibold mb-2">Verify trusted identities</h3>
          <p class="text-body-text">Flow comes with a companion authentication app for iOS and Android, TOZ Auth, to verify access requests from your team and your guests. You can also add our biometric TOZ ID Card, or other FIDO compliant hardware keys to step-up multiple factors of identity verification.</p>
        </div>
      </div>

      <!-- Buttons Row -->
      <div class="button-row flex justify-center mt-10 gap-4">
        <button class="bg-btn-alt-bg text-white py-2 px-6 rounded-lg font-bold text-lg uppercase transition-transform transform hover:scale-105 hover:bg-btn-alt-bg-hover" @click="goToFeatures">Learn More</button>
        <button class="bg-btn-bg text-white py-2 px-6 rounded-lg font-bold text-lg uppercase transition-transform transform hover:scale-105 hover:bg-btn-bg-hover" @click="goToGettingStarted">Get Started</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'KeyFeaturesSection',
  mounted() {
    this.addIntersectionObserver();
  },
  methods: {
    addIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };
      const callback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      };
      const observer = new IntersectionObserver(callback, options);
      const targets = document.querySelectorAll('.scalable');
      targets.forEach((target) => {
        observer.observe(target);
      });
    },
    goToFeatures() {
      this.$router.push({ name: 'Features' });
    },
    goToGettingStarted() {
      this.$router.push({ name: 'GettingStarted' });
    }
  }
};
</script>

<style scoped>
.scalable {
  transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
}

.scalable.visible {
  transform: scale(1.05);
  filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.2));
}
</style>