<template>
  <div :class="['hero-section', isMobile ? 'bg-hero-realm-mobile' : 'bg-hero-realm']">
    <div class="hero-content">
      <h1 class="hero-headline">Leave your (cyber) insecurities behind</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroFeaturesSection',
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 1024;
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1024;
    }
  },
};
</script>