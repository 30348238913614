<template>
    <div class="content">
      <h2>Pricing</h2>
      <!-- Add content for Pricing page -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'PricingPage',
  };
  </script>