<template>
  <div class="page-container flex flex-col min-h-screen">
    <div class="content-wrap flex-1">
      <FeaturesSection />
      <div class="scroll-container relative z-10 pointer-events-none">
        <FAQSection class="faq-section pointer-events-auto" />
      </div>
    </div>
    <HeroFeaturesSection class="hero-section fixed bottom-0 left-0 w-full h-[625px] transform transition-transform duration-500 ease z-0" />
    <FooterSection />
  </div>
</template>

<script>
import FeaturesSection from '../components/FeaturesSection.vue';
import FAQSection from '@/components/FAQSection.vue';
import HeroFeaturesSection from '@/components/HeroFeaturesSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'FeaturesPage',
  components: {
    FeaturesSection,
    FAQSection,
    HeroFeaturesSection,
    FooterSection,
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const faqSection = document.querySelector('.faq-section');
      const heroSection = document.querySelector('.hero-section');
      const faqRect = faqSection.getBoundingClientRect();

      if (window.innerHeight >= faqRect.bottom) {
        faqSection.classList.add('detached');
        heroSection.classList.add('revealed');
      } else {
        faqSection.classList.remove('detached');
        heroSection.classList.remove('revealed');
      }
    }
  }
};
</script>

<style scoped>
.faq-section.detached {
  position: relative;
  margin-bottom: 525px;
}

.hero-section.revealed {
  transform: translateY(0);
}

.faq-section {
  pointer-events: auto;
}

.hero-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 625px;
  z-index: -1;
}
</style>