<template>
  <div class="faq-container">
    <div class="faq-content white-box">
      <h2 class="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h2>
      <div class="search-container mb-4 flex justify-center">
        <input type="text" v-model="searchQuery" placeholder="Search FAQs" @input="filterFaqs" class="w-full max-w-xl p-2 border border-gray-300 rounded-md" />
      </div>
      <div class="category-container mb-4 flex justify-center flex-wrap">
        <span :class="[activeCategory === 'All' ? 'menu-hover active font-bold underline' : 'menu-hover']" @click="filterByCategory('All')">All</span>
        <span :class="[activeCategory === 'Product' ? 'menu-hover active font-bold underline' : 'menu-hover']" @click="filterByCategory('Product')">Product</span>
        <span :class="[activeCategory === 'Compliance' ? 'menu-hover active font-bold underline' : 'menu-hover']" @click="filterByCategory('Compliance')">Compliance</span>
        <span :class="[activeCategory === 'Technology' ? 'menu-hover active font-bold underline' : 'menu-hover']" @click="filterByCategory('Technology')">Technology</span>
        <span :class="[activeCategory === 'Support' ? 'menu-hover active font-bold underline' : 'menu-hover']" @click="filterByCategory('Support')">Support</span>
        <span :class="[activeCategory === 'Company' ? 'menu-hover active font-bold underline' : 'menu-hover']" @click="filterByCategory('Company')">Company</span>
      </div>
      <div class="accordion-container relative">
        <div class="accordion max-h-[310px] overflow-y-auto relative">
          <div v-for="(faq, index) in filteredFaqs" :key="index" class="accordion-item border-b border-gray-300 cursor-pointer py-2">
            <div @click="toggle(index)" class="accordion-header font-semibold text-lg">
              {{ faq.question }}
            </div>
            <div v-show="activeIndex === index" class="accordion-body p-4">
              {{ faq.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQSection',
  data() {
    return {
      activeIndex: null,
      searchQuery: '',
      activeCategory: 'All',
      faqs: [
        { question: 'What is TOZ Flow?', answer: 'TOZ Flow is a true end-to-end encrypted team collaboration product designed to make it easier for organizations to work internally and externally with sensitive data by addressing common vulnerabilities found in everyday workflows. Flow was developed with NIST cybersecurity controls in mind, and key features we provide align with these controls.', category: 'Product' },
        { question: 'How does Flow compare with tools like Slack, or Teams?', answer: 'Flow has been designed from the ground up as an integrated solution offering the highest possible security framework for collaborating with others around sensitive data. Slack, Teams, and others in our space, were not designed with compliance and security in mind from the start. Others charge extra in special editions to provide features we ship as standard, such as end-to-end encryption, privileged access management, zero knowledge identity and key management, to name a few. Flow stands alone as a turnkey product that makes business safer doing business.', category: 'Product' },
        { question: 'Can I view files I upload into Flow?', answer: 'TOZ Flow offers a document and PDF viewer that supports PDF, Microsoft Office, video, images, HTML, and many other formats for viewing.', category: 'Product' },
        { question: 'What types of files can I colloborate with others on and what can I do?', answer: 'TOZ Flow offers a native DOCX editing for your importan documents. You can import, open, and edit any DOCX document, or create new documents. You can change fonts, sizes, and headings, align text and control line spacing, and insert bulleted and numbered lists. In addition, users can collaborate on our Notes and Credential features. All collaborative features are end-to-end encrypted, so only one user can work on a document at a time to preserve protection.', category: 'Product' },       
        { question: 'Can I use my own identity provider, like Azure, with TOZ Flow?', answer: 'TOZ Flow customers can use our TOZ ID identity management service, but Flow can also be seamlessly integrated with your identity provider service of use, such as Microsoft Azure. Azure company users can just log in with their Azure credentials and use TOZ Flow. Our managed service partners can help you get started.', category: 'Product' },
        { question: 'How do I connect my SSO apps?', answer: 'You can connect your SSO apps using OpenID-connect and SAML, ensuring all of your SSO apps can be served to your users based on their rights. SSO apps can be assigned to all realm users, or conveniently assigned only to our privileged access managed groups, further protecting application access and only extending it to those that require access for as long as it is needed. We recommend you work with our managed service partners to correctly adminstrate SSO access.', category: 'Product' },
        { question: 'How do I sign up for TOZ Flow?', answer: 'At the moment, TOZ Flow is available through our managed service partners, please check out https://www.tozflow.com/getting-started and fill out the form and one of our partners will contact you.', category: 'Product' },
        { question: 'Do you support on-premise and self-hosted options?', answer: 'Yes! TOZ Flow is a cloud software as a service product and is not presently available for on-premise or self-hosted applications. The TOZ Platform, which powers TOZ Flow, does offer these options though, and more infomation can be found at https://developers.tozny.com/.', category: 'Product' },
        { question: 'How does billing work?', answer: 'Billing is handled through our managed service partners at this time on a per user basis, please go to https://www.tozflow.com/getting-started and fill out the form and one of our partners will contact you.', category: 'Product' },
        { question: 'What browsers are supported for using TOZ Flow?', answer: 'TOZ products are supported on Firefox, Safari, and Chrome browsers for Windows, macOS, Linux, iOS and Android platforms.', category: 'Product' },
        { question: 'What happens if I delete my realm?', answer: 'This is an action that cannot be undone, TOZ has no ability to restore deleted realms.', category: 'Product' },
        { question: 'What type of encryption does Flow use to secure data?', answer: 'Flow uses end-to-end encryption to secure your data, using the libSodium crypto library.', category: 'Technology' },
        { question: 'Can I trust your encryption?', answer: 'Yes. Our cryptographic libraries are open source and vetted by industry experts.', category: 'Technology' },
        { question: 'Who owns the data stored with TOZ Flow?', answer: 'You do. We design every product with our customer’s privacy and security as our first consideration. TOZ does not have keys to your encrypted data and cannot access it at any time.', category: 'Product' },
        { question: 'Does TOZ Flow offer self-hosted, on-premise, or white label solutions?', answer: 'At the moment, TOZ Flow is only available as Software as a Service (SaaS) offering, running on top of Amazon AWS.', category: 'Product' },
        { question: 'How does TOZ Flow ease compliance with cybersecurity regulations?', answer: 'TOZ Flow features such as our privileged access managed (PAM) groups, end-to-end encrypted storage, identity access management (IAM), and multi-factor authentication (MFA), meet many NIST cybersecurity controls that are recommended for any organization. It is core to our product design to support common workflows that our customers perform that aligns with controls specified by authorities such as NIST, and with cybersecurity insurance providers.', category: 'Compliance' },
        { question: 'What is NIST?', answer: 'NIST is the National Institute of Standards and Technology at the U.S. Department of Commerce. They are the primary authority offering controls and guidelines on how organizations should protect themselves and their customer data from threats.', category: 'Compliance' },
        { question: 'What is an example of how TOZ Flow meets cybersecurity regulations?', answer: 'A fundamental concept that is core to NIST publications, including NIST 800-53, is the "Principle of Least Privilege" which means that people only have the rights and permissions required in order to perform their roles and responsibiliites. This is a central philosophy driving the Flow product roadmap. For example, our privileged access managed groups provide a user experience that helps an organization meet "Access Control" family of controls, such as AC-2 (Account Management), AC-3 (Access Enforcement), AC-5 (Separation of Duties), and AC-6 (Least Privilege). These controls address the Principle of Least Privilege, by requiring organizations to grant users and processes only the rights necessary to accomplish their assigned tasks and responsibilities, for only the amount of time required through the use of access requests and setting expiring privilges by default.', category: 'Compliance' },
        { question: 'What is the NIST Cybersecurity Framework?', answer: 'The NIST Cybersecurity Framework (CSF) helps organizations of all sizes better understand, manage, and reduce their cybersecurity risk and protect their networks and data. The NIST CSF functions are to provide any organization a framework to govern, identify, protect, detect, respond, and recover in a cybersecurity context. TOZ Flow is designed using NIST CSF as a guideline for how our features map to controls organizations are told to use, and towards how organizations are audited for compliance.', category: 'Compliance' },
        { question: 'Does TOZ provide setup support?', answer: 'TOZ provides some getting started and setup guides on our help page for Flow, further setup support is offered through our service provider partners. Please go to https://www.tozflow.com/getting-started and fill out the form and one of our partners will contact you.', category: 'Support' },
        { question: 'How do I restore a forgotten password?', answer: 'On the login page you will find a link to use if you forgot or need to change your password. We suggest always also checking in with your company realm admin.', category: 'Support' },
        { question: 'Where can I find support to help me get started?', answer: 'TOZ provides some getting started and setup guides on our help page for Flow, further setup support is offered through our service provider partners. Please go to https://www.tozflow.com/getting-started and fill out the form and one of our partners will contact you.', category: 'Support' },
        { question: 'How do I contact TOZ for support?', answer: 'You can email us at support@tozny.com with questions, all primary support is handled by our managed service partners.', category: 'Support' },
        { question: 'What is MFA?', answer: 'Multi-factor authentication (MFA) factors categories are generally defined as mutiple levels of proving a user has the rigths to access something. TOZ authentication factors are defined as (in order of priority): 1) username and password, 2) possession of mobile phone, 3) phone biometric to access apps, 4) authenticator app (soft token), 5) token tap (hard token), 6) biometric token tap (hard token). Hardware tokens can be purchased from TOZ, and our product supports other FIDO compliant tokens such as YubiKey. Extra charges apply to add the hardware token service.', category: 'Technology' },
        { question: 'What is TOZ ID?', answer: 'TOZ ID is a single sign-on (SSO) service with privacy and end-to-end encryption built in. Our cryptography-at-the-edge approach delivers identity protection where you need it and streamlines protected access for your business and employees with SSO. TOZ ID also makes it easy to integrate with other identity service providers, such as Microsoft Azure for example, enabling your organization to use TOZ Flow by logging in with your service.', category: 'Technology' },
        { question: 'Can TOZ access, read, or decrypt my data?', answer: 'No. You hold the keys to your data, and are the only one with authorization to read, write, encrypt, or decrypt your data. TOZ Flow is a true end-to-end only service, and no data is ever stored unencrypted at rest, or in transit.', category: 'Technology' },
        { question: 'What about TOZ the company, what do you do?', answer: 'TOZ has a deep legacy of developing advanced cybersecurity software and hardware technology, which until recently was focused on securing complex projects for the government, collaborating on efforst with DARPA and NIST. We have recently made the move from focusing solely on advanced projects to providing our platform for developers, which are now integrated into software products from companies like Wind River, and on launching new turnkey products that focus on specific problems we are out to solve, such as TOZ Flow.', category: 'Company' },
        { question: 'Where is TOZ located?', answer: 'TOZ is headquarted in Portland, Oregon. More information about us can be found at tozny.com.', category: 'Company' },
        { question: 'How can I reach TOZ?', answer: 'You can email us at info@tozny.com and let us know what you would like to discuss!', category: 'Company' },
      ],
      filteredFaqs: [],
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    filterFaqs() {
      if (this.activeCategory === 'All') {
        this.filteredFaqs = this.faqs.filter(faq => {
          const searchTerm = this.searchQuery.toLowerCase();
          return faq.question.toLowerCase().includes(searchTerm) || faq.answer.toLowerCase().includes(searchTerm);
        });
      } else {
        this.filteredFaqs = this.faqs.filter(faq => {
          const searchTerm = this.searchQuery.toLowerCase();
          return (faq.question.toLowerCase().includes(searchTerm) || faq.answer.toLowerCase().includes(searchTerm)) && faq.category === this.activeCategory;
        });
      }
    },
    filterByCategory(category) {
      this.activeCategory = category;
      this.searchQuery = '';
      this.filterFaqs();
    }
  },
  mounted() {
    this.filteredFaqs = this.faqs;
  },
};
</script>

<style scoped>
.accordion-overlay {
  content: '';
}
</style>