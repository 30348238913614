<template>
  <header :class="{ 'scrolled': scrolled }">
    <nav class="flex justify-between items-center p-4">
      <router-link to="/" class="logo">
        <img src="@/assets/logo.svg" alt="TOZ Flow Logo" class="logo-img">
      </router-link>
      <div class="menu-icon lg:hidden" @click="toggleMenu">
        <i :class="menuOpen ? 'fas fa-times' : 'fas fa-bars'"></i>
      </div>
      <ul :class="['menu', { 'menu-open': menuOpen }]" class="hidden lg:flex space-x-4">
        <li><router-link to="/features" class="menu-hover nav-link">Features</router-link></li>
       <!-- <li><router-link to="/pricing" class="menu-hover nav-link">Pricing</router-link></li> 
       <li><a href="https://enterprise.tozflow.com/" class="menu-hover nav-link">Sign In</a></li> -->
        <li><router-link to="/getting-started" class="menu-hover get-started-btn">Get Started</router-link></li>
      </ul>
    </nav>
    <div v-if="menuOpen" class="mobile-menu">
      <div class="menu-close" @click="toggleMenu">
        <i class="fas fa-times"></i>
      </div>
      <ul class="flex flex-col items-center justify-center h-full">
        <li><router-link to="/features" class="menu-hover nav-link" @click="toggleMenu">Features</router-link></li>
        <!--<li><router-link to="/pricing" class="menu-hover nav-link" @click="toggleMenu">Pricing</router-link></li> 
        <li><a href="https://enterprise.tozflow.com/" class="menu-hover nav-link">Sign In</a></li>-->
        <li><router-link to="/getting-started" class="menu-hover get-started-btn" @click="toggleMenu">Get Started</router-link></li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  data() {
    return {
      scrolled: false,
      menuOpen: false,
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.style.overflow = ''; // Reset overflow on unmount
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  transition: background-color 0.3s;
  z-index: 10;
}

header.scrolled {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
}

header.scrolled .get-started-btn {
  color: white;
}

.logo-img {
  height: 50px;
  transition: height 0.3s ease;
}

.nav-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.menu-hover:hover {
  color: #ccc;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.menu {
  display: none;
}

.menu-open {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  color: white;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  color: white;
}

.mobile-menu li {
  margin: 20px 0;
  color: white;
}

.mobile-menu .nav-link,
.mobile-menu .get-started-btn {
  color: white !important; /* Ensure text is white */
}

.menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

@media (min-width: 1024px) {
  .menu {
    display: flex;
  }
}

.get-started-btn {
  background-color: #6A1B9A;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.get-started-btn:hover {
  background-color: #4A148C;
  text-decoration: none;
}

@keyframes ripple {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.ripple {
  animation: ripple 1s infinite ease-in-out;
  animation-delay: 0.5s;
}
</style>