<template>
	<div class="start-section">
		<MainHeader />
		<div class="hero-section">
			<div class="hero-content">
				<h1>Leave your (cyber) insecurities behind</h1>
			</div>
		</div>
		<div class="main-container">
			<div class="form-container">
				<h2>Get with the Flow</h2>
				<form @submit.prevent="submitForm">
					<div class="form-row">
						<div class="form-group">
							<label for="first-name">First name *</label>
							<input type="text" id="first-name" v-model="form.firstName" required />
						</div>
						<div class="form-group">
							<label for="last-name">Last name *</label>
							<input type="text" id="last-name" v-model="form.lastName" required />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group">
							<label for="work-email">Work email *</label>
							<input type="email" id="work-email" v-model="form.workEmail" required />
						</div>
						<div class="form-group">
							<label for="company">Company *</label>
							<input type="text" id="company" v-model="form.company" required />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group">
							<label for="company-size">Company Size *</label>
							<select id="company-size" v-model="form.companySize" required>
								<option value="1-15">1-15</option>
								<option value="16-50">16-50</option>
								<option value="51-100">51-100</option>
								<option value="100+">100+</option>
							</select>
						</div>
						<div class="form-group">
							<label for="phone-number">Phone number</label>
							<input type="tel" id="phone-number" v-model="form.phoneNumber" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group">
							<label for="help">How can we help you? *</label>
							<select id="help" v-model="form.help" required>
								<option value="msp-discuss">
									I would like to be connected with one of your service partners to discuss adding
									Flow to my business
								</option>
								<option value="msp-partner">
									I am interested in becoming a managed service partner and bringing Flow to my
									customers
								</option>
								<option value="other">I have another inquiry</option>
							</select>
						</div>
					</div>
					<div class="form-group">
						<label for="comments">Add a Comment</label>
						<textarea id="comments" v-model="form.comments"></textarea>
					</div>
					<div class="form-group">
						<label for="consent" class="consent"
							>By submitting this form, you are confirming that you agree and consent with how TOZ
							handles your personal data as defined in the
							<a href="/privacy">privacy policy</a>.</label
						>
					</div>
					<div class="form-group">
						<label for="consent" class="consent"
							>Have product questions? Visit our
							<a href="https://enterprise.tozflow.com/help">help</a> page.</label
						>
					</div>
					<div class="form-group newsletter">
						<input type="checkbox" id="newsletter" v-model="form.newsletter" />
						<label for="newsletter">Add me to the Flow email list to keep me informed!</label>
					</div>
					<button type="submit" class="submit-button">SUBMIT</button>
				</form>
			</div>
			<div class="additional-info">
				<div class="info-text">
					<p>
						Flow is currently available through our growing network of partners, such as Covenant
						Global, a cloud service provider (CSP) serving enterprise customers worldwide. Our
						partners can:
					</p>
					<ul>
						<li>Provide pricing information for the Flow service</li>
						<li>Discuss your company needs and demonstrate how Flow can work for you</li>
						<li>Help you get setup and manage your ongoing administration</li>
					</ul>
					<p>
						Interested in becoming a partner? Fill out the form and select the option in the “how
						can we help you?” menu.
					</p>
				</div>
				<div class="partners">
					<p class="featured-partner">Featured Partner</p>
					<img src="@/assets/covenant_logo.png" alt="Covenant Global" />
					<blockquote>
						<p>
							“As the premier partner, Covenant Global guides customer implementations with
							extensive cloud, security, and compliance expertise. TOZ Flow offers sophisticated
							capabilities to secure business team workflows, and our experts can further customize
							Flow to meet your unique business requirements using industry best practices.”
						</p>
					</blockquote>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MainHeader from './MainHeader.vue';
import axios from 'axios';

export default {
	name: 'StartSection',
	components: {
		MainHeader
	},
	data() {
		return {
			form: {
				firstName: '',
				lastName: '',
				workEmail: '',
				company: '',
				companySize: '',
				phoneNumber: '',
				help: '',
				comments: '',
				consent: false,
				newsletter: false
			}
		};
	},
	methods: {
		async submitForm() {
			console.log('Form is being submitted...', this.form);
			try {
				const response = await axios.post('https://www.securewithflow.com/api/send-email', this.form);

				if (response.status === 200) {
					alert('Form submitted successfully!');
					this.resetForm();
				} else {
					console.error('Error:', response.data);
					alert('There was an error submitting the form.');
				}
			} catch (error) {
				console.error('Error:', error);
				alert('There was an error submitting the form.');
			}
		},
		resetForm() {
			this.form = {
				firstName: '',
				lastName: '',
				workEmail: '',
				company: '',
				companySize: '',
				phoneNumber: '',
				help: '',
				comments: '',
				consent: false,
				newsletter: false
			};
		}
	}
};
</script>

<style scoped>
.start-section {
	position: relative;
}

.hero-section {
	background: url('@/assets/hero_start.jpg') no-repeat center center;
	background-size: cover;
	color: white;
	text-align: left;
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 600px;
	z-index: 1;
}

.hero-content {
	max-width: 800px;
}

.hero-content h1 {
	font-size: 3.25rem;
	font-weight: 700;
	color: white;
	white-space: nowrap;
}

@media (max-width: 1024px) {
	.hero-content h1 {
		white-space: normal;
		font-size: 2.5rem;
	}
}

.main-container {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	margin-top: -100px; /* Adjust if needed */
}

@media (min-width: 768px) {
	.main-container {
		flex-direction: row;
		align-items: flex-start;
		margin-top: -100px;
	}
}

.form-container {
	background: white;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	padding: 2rem;
	margin-bottom: 2rem;
	flex: 1;
}

form .form-group {
	margin-bottom: 1rem;
}

form .form-row {
	display: flex;
	flex-direction: column;
}

@media (min-width: 768px) {
	form .form-row {
		flex-direction: row;
		justify-content: space-between;
	}

	form .form-row .form-group {
		flex: 0 0 48%;
	}
}

form label {
	display: block;
	margin-bottom: 0.5rem;
}

form input,
form select,
form textarea {
	width: 100%;
	padding: 0.5rem;
	border: 1px solid #ddd;
	border-radius: 5px;
}

form textarea {
	height: 100px;
}

.submit-button {
	background-color: #6a1b9a;
	color: white;
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	font-size: 1rem;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.submit-button:hover {
	background-color: #4a148c;
}

.additional-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	margin-left: 2rem;
	margin-top: 8rem;
}

@media (max-width: 767px) {
	.additional-info {
		margin-left: 0;
		margin-top: 2rem;
	}
}

.info-text {
	margin-bottom: 2rem;
}

.info-text p {
	font-size: 1rem;
	line-height: 1.6;
	color: #333;
	margin-bottom: 1rem;
}

.info-text ul {
	list-style: disc;
	margin-left: 40px;
	padding-left: 0px;
	margin-bottom: 1rem;
}

.info-text ul li {
	margin-bottom: 0;
}

.partners {
	width: 100%;
	height: auto;
	max-width: 273px;
	margin-bottom: 1rem;
	margin: 0 auto 1rem auto;
}

.partners p {
	font-size: 1rem;
	color: #333;
}

.featured-partner {
	font-weight: bold;
	font-size: 1rem;
	margin-bottom: 0.5rem;
}

.consent {
	font-weight: normal;
	margin-top: 1rem;
}

.newsletter {
	display: flex;
	align-items: center;
	font-weight: normal;
	margin-left: 10px;
}

.newsletter input {
	margin-right: 0.5rem;
	margin-top: -0.6rem;
	width: auto;
}

.newsletter label {
	line-height: 1.5rem;
}

h2 {
	font-size: 2rem;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	text-align: left;
}

blockquote {
	font-style: italic;
	color: #555;
	margin: 1rem 0;
	padding: 1rem 1.5rem;
	border-left: 4px solid #6a1b9a;
	background: #f4f4f4;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	max-width: 500px;
	margin: 1rem auto;
}

blockquote p {
	margin: 0;
	padding: 0;
}
</style>
