<template>
  <div>
    <MainHeader />
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MainHeader from './components/MainHeader.vue';

export default defineComponent({
  name: 'App',
  components: {
    MainHeader,
  },
});
</script>

<style>
/* Global styles */
body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
}

p {
  color: #555;
}

a {
  color: #1d4ed8;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>