import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import FeaturesPage from '../views/FeaturesPage.vue';
import PricingPage from '../views/PricingPage.vue';
import GettingStartedPage from '../views/GettingStartedPage.vue';
import PrivacyPage from '../views/PrivacyPage.vue';
import TermsPage from '../views/TermsPage.vue';

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/features', name: 'Features', component: FeaturesPage },
  { path: '/pricing', name: 'Pricing', component: PricingPage },
  { path: '/getting-started', name: 'GettingStarted', component: GettingStartedPage },
  { path: '/privacy', name: 'Privacy', component: PrivacyPage },
  { path: '/terms', name: 'Terms', component: TermsPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;