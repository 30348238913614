<template>
	<div class="secure-workflows">
		<h2 class="section-title">Why TOZ Flow</h2>
		<div class="carousel-container">
			<swiper
				:slides-per-view="1"
				:navigation="true"
				:pagination="{ clickable: true }"
				:autoplay="{ delay: 5000,disableOnInteraction: false}"
				ref="swiper"
				@swiper="getRef" 
				>
			<swiper-slide v-for="(slide, index) in slides" :key="index" @click="pauseAutoplay">
					<img :src="require(`@/assets/${slide.image}`)" :alt="slide.text" class="slide-image" />
					<div class="slide-text">{{ slide.text }}</div>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore from "swiper";
import { Pagination, Navigation,Autoplay } from 'swiper/modules';

SwiperCore.use([Navigation,Autoplay,Pagination]);
const swiper = ref(null)

export default {
	name: 'SecureWorkflowsSection',
	components: {
		Swiper,
		SwiperSlide
	},
	data() {
		return {
			slides: [
				{
					text: 'Flow addresses common vulnerabilities found in everyday workflows with an integrated suite aimed at reducing the overall attack surface for an organization.',
					image: 'slide0.png'
				},
				{
					text: 'Share with trusted people and groups inside of your company while preserving end-to-end encryption, and easily revoke access at any time.',
					image: 'slide1.png'
				},
				{
					text: 'Share with people outside of your company using encrypted links to secure your secrets, and establishing trusted realms with your close partners.',
					image: 'slide2.png'
				},
				{
					text: 'Every note, file, document, credential, is encrypted from end-to-end and stored in each users personal vault, and can only be decrypted by the right identity.',
					image: 'slide3.png'
				},
				{
					text: 'Privileged access managed groups make it easy to collaborate with your team and guests in safe spaces, with granular access control.',
					image: 'slide4.png'
				},
				{
					text: 'Easily manage password credentials and SSO applications. Assign rights to users and to groups. Users can safely launch their apps from the Launchpad.',
					image: 'slide5.png'
				},
				{
					text: 'Flow combines management of identities, privileged access control, passwords, storage, document collaboration, apps, multi-factor authentication, and secure sharing all in one secure package.',
					image: 'slide6.png'
				}
			]
		};
	},

	methods: {
		getRef (swiperInstance) {
			swiper.value = swiperInstance
		},
		pauseAutoplay() {
			swiper.value.autoplay.stop();
			setTimeout(() => {
				swiper.value.autoplay.start();
			}, 30000);
		}
	}
};
</script>

<style scoped>
.secure-workflows {
	background-color: #f8f9fa;
	padding: 2rem 0;
}

.carousel-container {
	max-width: 800px;
	margin: 0 auto;
	text-align: center;
}

.section-title {
	text-align: center;
	margin-bottom: 1rem;
}

.slide-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.slide-image {
	width: 100%;
	max-width: 600px;
	border-radius: 8px;
	margin: 0 auto 0.05rem auto;
	filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.slide-text {
	margin-top: 10px;
	padding: 40px;
	text-align: center;
	font-size: 1.25rem;
}

.swiper-pagination-bullet {
	background: #007bff;
	width: 20px;
	height: 20px;
}

.swiper-pagination-bullet-active {
	background: #ff6347;
	width: 25px;
	height: 25px;
}
</style>
