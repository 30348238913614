<template>
    <div>      
      <HeroSection />
      <KeyFeaturesSection />
      <SecureWorkflowsSection />
      <FAQSection />
      <FooterSection />
    </div>
  </template>
  
  <script>
  import HeroSection from '../components/HeroSection.vue';
  import KeyFeaturesSection from '../components/KeyFeaturesSection.vue';
  import SecureWorkflowsSection from '@/components/SecureWorkflowsSection.vue';
  import FAQSection from '@/components/FAQSection.vue'; 
  import FooterSection from '@/components/FooterSection.vue';
  
  export default {
    name: 'HomePage',
    components: {
      HeroSection,
      KeyFeaturesSection,
      SecureWorkflowsSection,
      FAQSection,
      FooterSection,
    },
  };
  </script>