<template>
  <footer class="bg-footer-bg py-5 text-center">
    <nav class="flex justify-center flex-wrap">
      <a href="/privacy" class="text-white mx-2 no-underline p-1 transition-colors duration-300 hover:underline hover:text-gray-300">Privacy</a>
      <a href="/terms" class="text-white mx-2 no-underline p-1 transition-colors duration-300 hover:underline hover:text-gray-300">Terms</a>
      <a href="https://enterprise.tozflow.com/help-guide" class="text-white mx-2 no-underline p-1 transition-colors duration-300 hover:underline hover:text-gray-300">Help</a>
      <a href="https://www.tozny.com/" class="text-white mx-2 no-underline p-1 transition-colors duration-300 hover:underline hover:text-gray-300">Visit TOZ</a>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection',
};
</script>