### GettingStartedPage.vue

```vue
<template>
  <div class="page-container">
    <StartSection />
    <FooterSection />
  </div>
</template>

<script>
import StartSection from '../components/StartSection.vue';
import FooterSection from '../components/FooterSection.vue';

export default {
  name: 'GettingStartedPage',
  components: {
    StartSection,
    FooterSection,
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}
</style>