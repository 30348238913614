<template>
  <div class="hero-container">
  <div class="hero" :style="backgroundImageStyle">
      <div class="overlay" :style="{ opacity: overlayOpacity }"></div>
      <div v-if="showSpeech" class="speech-bubble" :style="{ top: bubblePosition }">
        “Okay team, I <em>think</em> I have a plan to keep us safe.”
      </div>
      <div class="hero-text" :style="{ opacity: headlineOpacity, transform: `translateY(${headlinePosition})` }">
        <h1>Securing how you work together can be... challenging</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'HeroSection',
  setup() {
    const showSpeech = ref(true);
    const overlayOpacity = ref(0);
    const headlineOpacity = ref(0);
    const headlinePosition = ref('100%');
    const bubblePosition = ref('40%');
    const heroTranslateY = ref('0px');
    const isMobile = ref(window.innerWidth <= 768);
    const backgroundImageStyle = ref({
      backgroundImage: `url(${isMobile.value ? require('@/assets/hero_mobile.png') : require('@/assets/hero.jpg')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    });
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY < window.innerHeight / 2) {
        overlayOpacity.value = (scrollY / (window.innerHeight / 2)) * 0.7;
        headlineOpacity.value = scrollY / (window.innerHeight / 2);
        headlinePosition.value = `${100 - (scrollY / (window.innerHeight / 2)) * 100}%`;
        bubblePosition.value = `${40 - (scrollY / (window.innerHeight / 2)) * 30}%`;
      } else {
        overlayOpacity.value = 0.7;
        headlineOpacity.value = 1;
        headlinePosition.value = '0%';
        bubblePosition.value = '20%';
        heroTranslateY.value = `-${scrollY - window.innerHeight / 2}px`;
      }
    };
   
    const checkMobile = () => {
      isMobile.value = window.innerWidth <= 768;
      backgroundImageStyle.value.backgroundImage = `url(${isMobile.value ? require('@/assets/hero_mobile.png') : require('@/assets/hero.jpg')})`;

    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', checkMobile);
      checkMobile();

    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkMobile);
    });

    return {
      backgroundImageStyle,
      showSpeech,
      overlayOpacity,
      headlineOpacity,
      headlinePosition,
      bubblePosition,
      heroTranslateY,
      isMobile
      
    };
  },
};
</script>

<style scoped>
.hero-container {
  height: 200vh; /* Ensure there's enough space to scroll */
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.hero-text {
  position: relative;
  color: white;
  font-size: 2rem;
  text-align: center;
  max-width: 1024px;
  padding: 0 20px;
  z-index: 1;
  transition: opacity 0.5s, transform 0.5s;
}

.speech-bubble {
  position: absolute;
  left: 20%;
  transform: translateX(-20%);
  background: white;
  color: black;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Increased shadow for 3D effect */
  font-size: 1.5rem;
  z-index: 10;
  transition: top 0.5s, box-shadow 0.5s;
}

.speech-bubble:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Additional shadow on hover */
}

.speech-bubble::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
</style>